import './App.css';
import Navbar from './components/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import { AnimatePresence } from 'framer-motion';

function App() {
  return (
    <div className="App">
      <Navbar />
      <section id="mainSection">
        <AnimatePresence>
          <Outlet></Outlet>
        </AnimatePresence>
      </section>
      <Footer />
    </div>
  );
}

export default App;
