import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import "./productCard.css";

export default function ProductCard() {
  return (
    <>
      <div className="container">
        <div className="row align-items-center">
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            className="col-lg-6 col-md-6 col-sm-12 col-12"
          >
            <img
              className="d-block"
              src="/assets/productCard.png"
              alt="Ürün Resmi"
            />
          </motion.div>
          <motion.div
            animate={{ opacity: [0, 1] }}
            className="col-lg-6 col-md-6 col-sm-12 col-12"
          >
            <div>
              <h1>Aktif Yaşamın Her Anında</h1>
              <h1>ORTHOFLEX</h1>
            </div>
            <br />
            <Link className="btn bg--dark" to="/urunler/ORTHOFLEX-GEL">
              Ürüne Git
            </Link>
          </motion.div>
          {/* <div className='productCard'>
                    <div className="productCardContent hidden-md hidden-sm hidden-xs">
                        <div>
                            <h1>Aktif Yaşamın Her Anında;</h1>
                            <h1>ORTHOFLEX</h1>
                        </div>
                        <br />
                        <Link className='btn bg--dark' to="/urunler/ORTHOFLEX-GEL">Ürüne Git</Link>
                    </div>
                    <div className='visible-md visible-sm visible-xs'>
                        <div>
                            <h1>Aktif Yaşamın Her Anında;</h1>
                            <h1>ORTHOFLEX</h1>
                        </div>
                        <br />
                        <Link className='btn bg--dark' to="/urunler/ORTHOFLEX-GEL">Ürüne Git</Link>
                        <br />
                        <br />
                        <br />
                    </div>
                </div> */}
        </div>
      </div>
      <br />
      <br />
      <br />
    </>
  );
}
