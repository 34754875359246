import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import "./products.css"

function Products(props: { data: { displayName: string, url: string[], name: string }[] }) {
    return (
        <motion.div animate={{ opacity: [0, 1] }} exit={{ opacity: 0 }} transition={{ duration: 2 }} className='container'>
            <div>
                <img
                    src='/assets/productsBanner.png'
                    alt="Dünya Markası Ürünler"
                />
            </div>
            <div id="productsContainer">
                <div className='row'>
                    {props.data.map((item, index) =>
                        <div className="col-lg-3 col-md-4 col-sm-6 my-5" key={index}>
                            <Link to={`/urunler/${item.name}`} className="d-flex flex-column">
                                <img
                                    src={item.url[0]}
                                />
                                <p>{item.displayName}</p>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </motion.div>

    )
}

export default Products