import { motion } from "framer-motion";

function StemCells() {
  return (
    <motion.div
      animate={{ opacity: [0, 1] }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      className="container"
    >
      <div>
        <img
          src="/assets/corporateBanner.png"
          alt="product detail banner"
        />
      </div>
      <br />
      <br />
      <div className="text-left">
        <h3>Kök Hücre Elde Etme Yöntemleri</h3>
        <h4>SVF (Stromal Vascular Fraction) Yağ Dokudan Elde Edilen Kök Hücreler</h4>
        <h4>B-MAC Kemik İliğinden Elde Edilen Kök Hücreler</h4>
        <h4>PRP (Platelet Rich Plazma) Kandan Elde Edilen Kök Hücreler</h4>
        <h3>Kök Hücre Nedir?</h3>
        <p>Kök hücreler ise vücutta tüm doku ve organların yapısını oluşturan hücrelerdir. Organizma içinde yer alan tüm
          hücrelere dönüşebilen ve ana hücre olarak tanımlanan kök hücreler, vücutta ihtiyaç duyulan her bölgede yer alır.
          Böylece hastalanan veya hasarlanan tüm doku ve organların yenilenmesinde rol oynar. İhtiyaç duyulan hücre tipine
          dönüşerek; hastalık, yaralanma ve diğer sebepler nedeniyle oluşan organ ve doku hasarı ya da kaybını onarır.
          Bölünebilen yapıları sayesinde, aynı türden kök hücrelerin oluşumunda da rol oynarken aynı zamanda kas ya da kan
          hücrelerine dönüşebilirler. Pek çok farklı türü bulunan kök hücreler, kişi henüz anne karnında iken gelişimin ilk
          basamaklarında önemli rol oynayarak, organ ve dokuların oluşumunu sağlar. Embriyonik kök hücre olarak tanımlanan
          bu hücrelerden farklı olarak insan vücudunda somatik kök hücreler yer alır. Dokuya özel ya da erişkin kök hücre olarak
          bilinen somatik kök hücrelerin yanı sıra iPS hücreleri olarak tanımlanan uyarılmış pluripotent kök hücreler de bulunur.
          Teknolojinin ve tıp biliminin gelişimi ile son zamanlarda yağ dokusundan ya da kemik iliğinden de alınabilen kök
          hücrelerin, bir başka kişiye nakli sayesinde pek çok farklı hastalık türü tedavi edilebilir. Hasta kişiye doğrudan damar
          yoluyla verilebileceği gibi hasarlı organa direkt olarak da nakledilebilen kök hücre, hasta vücuda girdiğinde çoğalarak
          organizmanın ihtiyaç duyduğu hücrelere dönüşerek, hastalıklı dokuyu ya da organı onarabilir.
        </p>
        <p><strong>Kök Hücre ve Türleri</strong></p>
        <p>Kök Hücreler vücudumuzdaki bütün dokuları ve organları oluşturan ana hücrelerdir. Bir organda pek çok farklı hücre bulunur. Kök hücreler doku veya organlarda bulunan hücrelerden zarar gören olduğunda; o hücreye dönüşerek organ ve dokuları tedavi eder. Sınırsız bölünebilme yetenekleri vardır. Kök hücreler aslında sürekli aktif halde tüm vücudumuzun yenilenmesini ya da gereken bölgelerin tedavisini sürekli sağlamaktadır. Toplu halde belli bir bölgeye yöneltilirse o bölgenin iyileşmesini sağlar.</p>
        <p>Günümüzde ise vücudumuzdaki kan hücrelerinden, yağ dokularından ve kemik iliğinden elde edilenden çok daha fazla kök hücrelerin kolaylıkla elde edilebileceği bulunmuştur. Aynı şekilde vücudumuzdan alınan dokuların da laboratuvarda çoğaltılabileceği keşfedilmiştir. Böylelikle Kök Hücreler neredeyse tüm hastalıkların tedavisinde kullanılabilir hale gelmiştir.<br /></p>
        <p><strong>Kök hücrelerin ortak özellikleri</strong></p>
        <ul>
          <li>■ Uygun bir büyüme ortamında çoğaltılabilirler.</li>
          <li>■ Sınırsız çoğalma yetenekleri vardır.</li>
          <li>■ Dokundukları hücrelere dönüşerek hücrelerin oluşturduğu doku ve organları tedavi ederler.</li>
          <li>■ Kendilerini yenileyebilir veya kendi hücre topluluklarının devamını sağlayabilirler.</li>
          <li>■ Vücudun bir yerindeki zedelenmeyi takiben bu dokuyu onarabilme ve onu işlevsel hale getirebilme potansiyeline sahiptirler.</li>
        </ul>
        <p>Erişkin kök hücreler embriyo ve fetüsten alınan hücrelerden farklıdır ve doğumdan sonra insan ya da hayvanlarda gelişen dokularda bulunur. Bununla birlikte bu hücrelerin elde edildiği en uygun yer bazı kemiklerin merkezinde yerleşmiş olan kemik iliğidir. Kemik iliği genelde üst kalça kemiğinin arkasında “krista iliaca” denen kemik bölümünden alınır. Kemik iliğinde;&nbsp;<strong>hematopoetik&nbsp;</strong>kök hücreler<strong>, endotelyal&nbsp;</strong>kök hücreler ve&nbsp;<strong>mezenkimal</strong>&nbsp;kök hücreleri de içeren farklı tipte kök hücreler yer almaktadır. Hematopoetik kök hücrelerin kanı oluşturduğu bilinmektedir ve lösemi ve ya anemi gibi kan rahatsızlıklarının tedavisinde kullanılmaktadır; endotelyal kök hücrelerin vasküler sistemi (arterler ve venler) tedavi ettiği bilinmektedir yani damarlar ile ilgili tedavilerde kullanılmaktadır. Mezenkimal kök hücrelerinin ise tahmin olarak yüz farklı hücreye dönüştüğü bilinmektedir bunlar ;kemik, kıkırdak, kas, sinir, karaciğer, pankreas hücreleri gibi pek çok farklı hücrelerdir.</p>
        <p><strong>Kök Hücre Elde Etme Yöntemleri</strong></p>
        <p><strong>1-SVF – Stromal Vasküler Fraksiyon</strong></p>
        <p>Yağ dokusundan kök hücre elde edilebileceği anlaşıldığında öncelikle yağın içerisindeki hücrelere ulaşmak için çeşitli enzimatik ve mekanik işlemlerle yağ hücreleri ayrıştırılarak geriye kalan ve pek çok farklı hücreyi bir arada barındıran kompleks kök hücre süspansiyona SVF adı verilir. İçeriğinde yetişkinde bulunan pek çok hücre türü barındırır. Bunlar farklı hücrelere dönüşme yeteneği olan mezenkimal, kan hücrelerini oluşturan hemotopoitik, damarları tedavi eden endotelyal, bağ dokularını oluşturan fibroblast gibi 80-100 farklı tedavi edici özelliği olan kök hücre grubunu içerisinde barındırır. Pek çok hücreyi barındırması sebebiyle intravenöz (damar yolu) ile hastaya verildiğinde içerdiği hücreler ilgili bölgeler tarafından tedavi edici hücre olarak kullanılır. İstenirse SVF in içerisinden hücreler özel olarak ayrıştırılarak; ayrı ayrı çoğaltılabilir ve farklı tedavilerde kullanılabilir.</p>
        <p>Aynı şekilde birden fazla hücrenin bir arada tedavi etmesi gereken yanık, otoimmun sistemi gibi hastalıklarda hücreler ayrıştırılmadan toplu olarak kullanılır.<br /></p>
        <p><strong>2-BMAC – Bonemarrow – Kemik İliği</strong></p>
        <p>BMAC-Kemik İliği &nbsp;<a href="https://tr.wikipedia.org/wiki/Genel_anestezi" title="Genel anestezi">genel anestezi</a>&nbsp;ya da&nbsp;<a href="https://tr.wikipedia.org/wiki/Lokal_anestezi" title="Lokal anestezi">lokal anestezi</a>&nbsp;ile&nbsp;<a href="https://tr.wikipedia.org/wiki/%C4%B0lium" title="İlium">ilium</a>&nbsp;kemiğinin&nbsp;<a href="https://tr.wikipedia.org/w/index.php?title=Ilyak_krest&amp;action=edit&amp;redlink=1" title="Ilyak krest (sayfa mevcut değil)">kristasından</a>&nbsp;bir iğne yardımıyla örnek alınır. Bir bacak kemiğinde ortalama 440 milyar hücre vardır.</p>
        <p>Bir kişiden hematopoetik kök hücreler alıp bunları başka birisine (<a href="https://tr.wikipedia.org/w/index.php?title=Allojenik&amp;action=edit&amp;redlink=1" title="Allojenik (sayfa mevcut değil)">Allojenik</a>), veya ileride aynı kişiye (<a href="https://tr.wikipedia.org/wiki/Otolog" title="">Otolog</a>)aktarmak mümkündür. Eğer verici ve alıcı uyumlu iseler aktarılan hücreler iliğe göç edip&nbsp;<a href="https://tr.wikipedia.org/w/index.php?title=Kan_h%C3%BCcreleri&amp;action=edit&amp;redlink=1" title="Kan hücreleri (sayfa mevcut değil)">kan hücreleri</a>&nbsp;üretmeye başlarlar. Kök hücreler genelde ya&nbsp;<a href="https://tr.wikipedia.org/w/index.php?title=%C4%B0lium_tepesi&amp;action=edit&amp;redlink=1" title="İlium tepesi (sayfa mevcut değil)">ilium tepesinden</a>&nbsp;<a href="https://tr.wikipedia.org/wiki/Genel_anestezi" title="Genel anestezi">genel anestezi</a>&nbsp;ile alınır (bu işlem pek çok iğne batırması gerektirir) veya kök hücrelerin kemikten kan dolaşımına salınmalarını sağlayan bazı ilaçlar kullanılır, ardından bu hücreler kandan izole edilerek donörden alınan kan tekrar kendisine verilir.</p>
        <p>&nbsp;Femur ya da leğen kemiğinden biyopsi ile alınması ve kök hücrelerin saflaştırılmaları ile elde edilirler. Ayrıca vericilerden önce ilaç verilerek kök hücrelerin kan içine çıkması sağlanıp sonrasında aferez yöntemi ile kandan kök hücrelerin toplanması ile elde edilirler. Kemik İliği Transplantasyon merkezlerinde kullanılan yöntemlerdir ve etkisi ispatlanmıştır.</p>
        <p><strong>3-PRP – Platelet Rich Plazma — Kan Hücreleri</strong></p>
        <p>PRP nasıl elde edilir ve nasıl etki eder?</p>
        <p>Vücudumuz en aktif damarsal aktivitesi olan bölgeden kan alınır ve bunlar çeşitli santrifüjleme yöntemi ile trombositler kaynaklı büyüme faktörleri ayrıştırılıp zenginleştirilir.</p>
        <p>&nbsp;Trombositler iyileşmeyi aktive eder ve hızlandırır. Yaralandığınızda, kan dolaşımınız yoluyla hasarlı dokulara giderler ve büyüme faktörleri adı verilen proteinleri serbest bırakırlar. Birçok farklı büyüme faktörü salgılarlar ve her biri iyileşme için gerekli olan hücresel aktiviteleri tetikler.</p>
        <p>PRP (trombosit kaynaklı büyüme faktörleri) ne için yapılır?</p>
        <ul>
          <li>■ Yeni kan damarı oluşumunu ve ciltte iyileşme ve onarımı hızlandırır,</li>
          <li>■ Yakındaki hücrelerdeki iyileştirici mekanizmaları etkinleştirir,</li>
          <li>■ Verildiği bölgeye kök hücreleri çeker (hızlı iyileşme ve onarım),</li>
          <li>■ Enflamasyonu düzenler,</li>
          <li>■ Sağlıklı dokuları korur,</li>
          <li>■ Yeni doku büyümesini destekleyen altyapıyı oluşturur.</li>
        </ul>
        <p>Tüm bu sonuçları PRP'yi doğrudan dokulara enjekte ederek elde edebiliriz. (Yüze PRP, Saça PRP)</p>
        <p>Trombosit kaynaklı büyüme faktörlerinde az miktarda mezenkimal kök hücre bulunmaktadır.<br /></p>
        <p><strong>Mezenkimal Kök Hücre</strong></p>
        <p>Başka bir hücreye dönüşme yeteneği olan hücreye verilen isimdir. Erişkin vaziyette bulunan mezenkimal kök hücreler bağ dokularda bulunur.&nbsp;Bu kök hücreler bulunduğu dokudan hasarlı bir dokuya geçebilmektedir. Hasarlı olan dokuların tamirini gerçekleştirirler ve hasarlı olan dokuyu tamir edebilirler. Aynı zamanda başka dokularda da hasar varsa o bölgeyi bulabilme özelliğine sahiptirler. Erişkin bireyde bulunan mezenkimal kök hücreler günümüzde kemik iliği ve kemik iliğinden 4 kat fazla bulunan yağ dokusundan elde edilir. Erişkin bir bireyden alınan mezenkimal kök hücre henüz bilinen 100 civarında farkı hücreye dönüşebilme yeteneğine sahiptir. Bunlar; kemik, kıkırdak, kas, sinir, karaciğer, pankreas hücreleri gibi pek çok farklı hücrelerdir.<br /></p>
        <p><strong>Mezenkimal kök hücrenin özellikleri:</strong></p>
        <ul>
          <li>■ Mezenkimal kök hücrelerin diğer hücrelere dönüşebilme özelliği bulunur.</li>
          <li>■ Akciğer, mide ve kemik dokusunda görünüm olarak farklılık kazanırlar. Bu durumun&nbsp;sebebi ortamdaki farklı koşullardır.</li>
          <li>■ Mezenkimal Kök Hücrelerin hasarlı hücre ile kaynaşma yetenekleri vardır. Sadece kendi bulunduğu dokudaki hasarı onarmakla kalmaz, başka dokudaki hasar durumunda da diğer dokuya geçer ve onarım sağlar.</li>
          <li>■ Bağ doku kökenli olmaları nedeniyle ilgili doku hücrelerinin gelişimine ve fonksiyonuna katkı sağlayabilmektedirler.</li>
          <li>■ Bağ doku hücreleri olan kas, yağ, kemik, kıkırdak, tendon, ligament gibi hücrelere farklılaşabilir.</li>
        </ul>
        <p>Sayısını artırabilmek için özel laboratuvar ortamında haftalarca süren kültürlerde çoğaltılması gereklidir. Bu da ciddi bir teknoloji, alt yapı, deneyim gerektirir ve maliyeti de fazladır. Bu nedenle dünyada sayılı sayıdaki merkezde klinik kullanıma uygun Mezenkimal Kök Hücre üretimi yapılmaktadır. Bu işlem için gerekli tüm işlemlerin uluslararası standartlarda kabul edilmiş (GMP Good manufacturing practice=iyi üretim uygulamaları) şekilde yapılması gerekmektedir.<br /></p>
        <p><strong>Hematopoetik Kök Hücre (Kan Hücresi)</strong></p>
        <p>Vücudumuzdaki kan hücrelerinin tümü hematopoetik kök hücreler denen, genç (olgunlaşmamış) hücrelerden oluşur. (Hematopoetik sözcüğü “kan oluşturan” anlamına gelir.)</p>
        <p>Kök hücreleri yeni hücreler oluşturmak için bölünmek üzere genellikle kemik iliğinde (kemiklerin içinde yer alan süngersi öz) hazır bulunurlar. İlk keşfedilen, en iyi tanımlanan ve klinikte yıllardan beri başarı ile uygulanan eşsiz hücrelerdir.<br /></p>
        <p><strong>Hematopoetik kök hücrelerinin görevleri:</strong></p>
        <ul>
          <li>■ Dokulara oksijen taşımak, immün sistemin işlemesini sağlamak ve kanamanın kontrolü gibi temel mekanizmaları kontrol etmektir.</li>
          <li>■ Kendi kendini yenileyebilen, tüm kan dokusunu farklılaştırarak oluşturabilen hücrelerdir.</li>
          <li>■ Hematopoetik kanserler (lösemi-lenfoma-myeloma), hematopoetik dışı kanserlerde,</li>
          <li>■ Genetik ve/veya kazanılmış kemik iliği hastalıkları olan,</li>
          <li>■ Aplastik anemi, talasemiler, orak hücre hastalığında ve artan sıklıkta otoimmün hastalıklarda kullanılır.</li>
        </ul>
        <p><strong>Endotelyal Kök Hücre</strong></p>
        <p>Endotelyal Kök Hücreler, kemik iliğinde olgunlaşıp, dolaşıma çıkarak vasküler(damar sistemi) hasarın olduğu bölgelerde yoğunlaşıp hasarın tamir edilmesinde merkezi rol alırlar. Endotelyal Kök Hücreler kemik iliğinden ayrıldıktan sonra hücre yüzey belirteçlerinde bir takım değişiklikler geçirerek olgun endotelyal hücrelere dönüşebilirler.</p>
        <p>Endotel Kök Hücrelerin bölünme yetenekleri ölçüsünde çoğalarak ve dolaşarak var olan damarları tedavi ederler.</p>
        <p><strong>Otolog Kök Hücre Üretimi</strong></p>
        <p>Vücudumuzda bulunan ve kendisi hariç başka bir hücreye dönüşme yeteneği olmayan hücrelerin, klinik ortamda çoğaltılarak; eksik olan bölgeye verilmesine otolog hücre üretimi adı verilir.</p>

      </div>
    </motion.div>

  );
}

export default StemCells;
