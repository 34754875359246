import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import products from "../datas/products.json";

function Navbar() {
  const [imgUrl, setimgUrl] = useState(
    "/assets/treatmens/dropdown-eklem-detail.png"
  );

  const productList = useMemo(() => products, []);
  const changeImg = (imgUrl: string) => {
    setimgUrl(imgUrl);
  };

  const handleButtonClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    let toggleElement = document.getElementById("menu-bar");
    let toggleClass = ["hidden-sm", "hidden-xs"];
    let classNameList = e.currentTarget.classList.value.split(" ");

    if (classNameList.some((p) => p === "toggled-class")) {
      e.currentTarget.classList.remove("toggled-class");
    } else {
      e.currentTarget.classList.add("toggled-class");
    }
    if (
      toggleElement?.classList.value.split(" ").some((p) => toggleClass.includes(p))
    ) {
      toggleElement.classList.remove(toggleClass[0]);
      toggleElement.classList.remove(toggleClass[1]);
    } else {
      toggleElement?.classList.add(toggleClass[0]);
      toggleElement?.classList.add(toggleClass[1]);
    }
  };

  const closeButtonClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id?: string
  ) => {
    let toggleElement = document.getElementById("menu-bar");
    let toggleClass = "hidden-xs";

    e.currentTarget.classList.remove("toggled-class");
    toggleElement?.classList.add(toggleClass);

    setTimeout(() => {
      if (id) {
        let element = document.getElementById(id);
        element?.classList.remove("dropdown--active");
        console.log(element?.classList.value);
      }
    }, 100);
  };

  return (
    <React.Fragment>
      <div className="bar bar--sm visible-sm visible-xs">
        <div className="container">
          <div id="smContactInfo" className="contactInfo visible-sm visible-xs">
            <span className="text-right">Arthrex | Ege Bölge Distrübütörü</span>
          </div>
          <div className="row">
            <div className="col-4 col-md-2">
              <Link to="/">
                <img
                  className="logo logo-dark"
                  alt="logo"
                  src="/assets/Brand.png"
                />
              </Link>
            </div>
            <div className="col-8 col-md-10 text-right">
              <a
                onClickCapture={(e) => handleButtonClick(e)}
                className="hamburger-toggle"
              >
                <i className="icon icon--sm stack-interface stack-menu"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav id="menu-bar" className="bar bar--sm bar-1 hidden-sm hidden-xs">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 hidden-xs hidden-sm d-flex align-items-end">
              <div className="bar__module m-0">
                <Link to="/">
                  <img
                    className="logo logo-dark"
                    alt="logo"
                    src="/assets/Brand.png"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-12 text-right text-left-xs text-left-sm">
              <ul className="contactInfo row justify-content-end menu-horizantal hidden-xs hidden-sm m-0">
                <li className="col-lg-6 col-md-6 col-sm-3 p-0">
                  <span>Arthrex | Ege Bölge Distrübütörü</span>
                </li>
              </ul>
              <div className="bar__module">
                <ul className="menu-horizontal text-left-lg text-right-md text-center-sm text-center-xs">
                  <li>
                    <Link to="/" onClickCapture={(e) => closeButtonClick(e)}>
                      Anasayfa
                    </Link>
                  </li>
                  <li className="dropdown" id="productDropDown">
                    <span className="dropdown__trigger">Ürünler</span>
                    <div className="dropdown__container">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 dropdown__content dropdown__content--lg">
                            <div className="pos-absolute col-lg-3 col-md-5 imagebg hidden-xs hidden-sm m-0 p-0">
                              <img
                                alt="Logo"
                                src="/assets/products.png"
                                style={{
                                  borderRadius: "6px",
                                  height: "100%",
                                  width: "100%",
                                }}
                              />
                              <div className="w-100 d-flex justify-content-center">
                                <Link
                                  to="/urunler"
                                  style={{ top: "-100px" }}
                                  className="btn btn-dark type--uppercase"
                                  onClickCapture={(e) =>
                                    closeButtonClick(e, "productDropDown")
                                  }
                                >
                                  <span className="btn__text">Tüm Ürünler</span>
                                </Link>
                              </div>
                            </div>
                            <div className="row justify-content-end">
                              <div className="col-lg-9">
                                <div className="row">
                                  <div className="col-lg-4 col-md-4 text-left text-center-sm text-center-xs">
                                    <span>
                                      <strong>Kök Hücre</strong>
                                    </span>
                                    <hr className="mt-0 w-75" />
                                    <ul className="menu-vertical">
                                      {productList
                                        .filter((p) => p.group === 1)
                                        .map((item, index) => (
                                          <li key={index}>
                                            <Link
                                              to={`/urunler/${item.name}`}
                                              onClickCapture={(e) =>
                                                closeButtonClick(
                                                  e,
                                                  "productDropDown"
                                                )
                                              }
                                            >
                                              {item.displayName}
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                  <div className="col-lg-4 col-md-4 text-left text-center-sm text-center-xs">
                                    <span>
                                      <strong>İleri Cerrahi</strong>
                                    </span>
                                    <hr className="mt-0 w-75" />
                                    <ul className="menu-vertical">
                                      {productList
                                        .filter((p) => p.group === 2)
                                        .map((item, index) => (
                                          <li key={index}>
                                            <Link
                                              to={`/urunler/${item.name}`}
                                              onClickCapture={(e) =>
                                                closeButtonClick(
                                                  e,
                                                  "productDropDown"
                                                )
                                              }
                                            >
                                              {item.displayName}
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                  <div className="col-lg-4 col-md-4 text-left text-center-sm text-center-xs">
                                    <span>
                                      <strong>Eklem İçi Enjeksiyonlar</strong>
                                    </span>
                                    <hr className="mt-0 w-75" />
                                    <ul className="menu-vertical">
                                      {productList
                                        .filter((p) => p.group === 3)
                                        .map((item, index) => (
                                          <li key={index}>
                                            <Link
                                              to={`/urunler/${item.name}`}
                                              onClickCapture={(e) =>
                                                closeButtonClick(
                                                  e,
                                                  "productDropDown"
                                                )
                                              }
                                            >
                                              {item.displayName}
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown" id="treatmentDropDown">
                    <span className="dropdown__trigger">Tedavİ Alanları</span>
                    <div className="dropdown__container">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-7 dropdown__content dropdown__content--lg">
                            <div className="pos-absolute col-lg-8 col-md-8 imagebg hidden-xs hidden-sm m-0 p-0">
                              <img
                                alt="Logo"
                                src={imgUrl}
                                style={{
                                  borderRadius: "6px",
                                  height: "100%",
                                  width: "100%",
                                }}
                              />
                            </div>
                            <div className="row justify-content-end">
                              <div className="col-lg-3 col-md-12 text-left text-center-sm text-center-xs">
                                <ul className="menu-vertical">
                                  <li
                                    onMouseOver={() =>
                                      changeImg(
                                        "/assets/treatmens/dropdown-eklem-detail.png"
                                      )
                                    }
                                  >
                                    <Link
                                      to="/tedavi-alanlari/eklem-sagligi"
                                      onClickCapture={(e) =>
                                        closeButtonClick(e, "treatmentDropDown")
                                      }
                                    >
                                      Eklem Sağlığı
                                    </Link>
                                  </li>
                                  <li
                                    onMouseOver={() =>
                                      changeImg(
                                        "/assets/treatmens/dropdown-spor-detail.png"
                                      )
                                    }
                                  >
                                    <Link
                                      to="/tedavi-alanlari/spor-cerrahisi"
                                      onClickCapture={(e) =>
                                        closeButtonClick(e, "treatmentDropDown")
                                      }
                                    >
                                      Spor Cerrahisi
                                    </Link>
                                  </li>
                                  <li
                                    onMouseOver={() =>
                                      changeImg(
                                        "/assets/treatmens/dropdown-estetik-detail.png"
                                      )
                                    }
                                  >
                                    <Link
                                      to="/tedavi-alanlari/medikal-estetik"
                                      onClickCapture={(e) =>
                                        closeButtonClick(e, "treatmentDropDown")
                                      }
                                    >
                                      Medikal Estetik
                                    </Link>
                                  </li>
                                  <li
                                    onMouseOver={() =>
                                      changeImg(
                                        "/assets/treatmens/dropdown-jinekoloji-detail.png"
                                      )
                                    }
                                  >
                                    <Link
                                      to="/tedavi-alanlari/jinekoloji"
                                      onClickCapture={(e) =>
                                        closeButtonClick(e, "treatmentDropDown")
                                      }
                                    >
                                      Jinekoloji
                                    </Link>
                                  </li>
                                  <li
                                    onMouseOver={() =>
                                      changeImg(
                                        "/assets/treatmens/dropdown-uroloji-detail.png"
                                      )
                                    }
                                  >
                                    <Link
                                      to="/tedavi-alanlari/uroloji"
                                      onClickCapture={(e) =>
                                        closeButtonClick(e, "treatmentDropDown")
                                      }
                                    >
                                      Üroloji
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                  >
                    <Link
                      to="/kok-hucre"
                      onClickCapture={(e) => closeButtonClick(e)}
                    >
                      Kök Hücre
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/yayinlar"
                      onClickCapture={(e) => closeButtonClick(e)}
                    >
                      Yayınlar
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/kurumsal"
                      onClickCapture={(e) => closeButtonClick(e)}
                    >
                      Kurumsal
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/iletisim"
                      onClickCapture={(e) => closeButtonClick(e)}
                    >
                      İletİşİm
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}

export default Navbar;
