import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carouselSlider.css"

function CarouselSlider(props: { imgUrls: string[], alt: string }) {

    return (
        <Carousel infiniteLoop axis='horizontal' showArrows={false} autoPlay showStatus={false}>
            {props.imgUrls.map((item, index) =>
                <div key={index}>
                    <img src={item} alt={props.alt} />
                </div>
            )}
        </Carousel>
    )
}

export default CarouselSlider