import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Corporate from "./pages/Corporate";
import Product from "./pages/Product";
import Contact from "./pages/Contact";
import Publication from "./pages/Publication";
import TreatmentArea from "./pages/TreatmentArea";
import Error404 from "./pages/Error404";
import StemCells from "./pages/StemCells";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <Error404 />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/urunler",
        element: <Product />,
        children: [
          {
            path: "/urunler/:name",
            element: <Product />,
          },
        ],
      },
      {
        path: "/tedavi-alanlari/:name",
        element: <TreatmentArea />,
      },
      {
        path: "/yayinlar",
        element: <Publication />,
      },
      {
        path: "/kurumsal",
        element: <Corporate />,
      },
      {
        path: "/iletisim",
        element: <Contact />,
      },
      {
        path: "/404",
        element: <Error404 />,
      },
      {
        path: "/kok-hucre",
        element: <StemCells />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
