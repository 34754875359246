import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

export default function Error404() {
  return (
    <motion.section
      animate={{ opacity: [0, 1] }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      className="height-100 text-center"
    >
      <div className="container pos-vertical-center">
        <div className="row">
          <div className="col-md-12">
            <h1 className="h1--large">404</h1>
            <p className="lead">Ooops.. Aradığınız Sayfaya Ulaşılamıyor.</p>
            <Link to="/">Ana Sayfa</Link>
          </div>
        </div>
      </div>
    </motion.section>
  );
}
