import * as React from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { pageThumbnailPlugin } from "./pdf";
import { Worker } from "@react-pdf-viewer/core";

function DisplayThumbnail(props: { fileUrl: string; pageIndex: number }) {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Cover } = thumbnailPluginInstance;
  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />,
  });
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
      <a href={props.fileUrl}>
        <div
          style={{ width: "250px", height: "320px", border: "1px solid black" }}
          className="d-flex justify-content-center"
        >
          <Viewer
            fileUrl={props.fileUrl}
            plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]}
          />
        </div>
      </a>
    </Worker>
  );
}

export default DisplayThumbnail;
