import React from 'react'
import { Link } from 'react-router-dom'

function TreatmentSlider(props: { data: { name: string, url: string, imgUrl: string }[] }) {
    return (
        <section className="text-center visible-md visible-sm visible-xs p-2">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="slider" data-paging="true" >
                            <ul className="slides p-0">
                                {props.data && props.data.length > 0 && props.data.map((item, index) =>
                                    <li className="col-md-6 col-12 mt-3" key={index}>
                                        <div className="product">
                                            <Link to={item.url}>
                                                <img src={item.imgUrl} alt={item.name} />
                                            </Link>
                                            <Link className="block" to={item.url}>
                                                <div>
                                                    <p>{item.name}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div >
            </div >
        </section >

    )
}

export default TreatmentSlider