import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Products from "../components/products/Products";
import products from "../datas/products.json";
import ProductDetail, {
  IProductDetail,
} from "../components/products/ProductDetail";
import { useScrollTo } from "../app/useScrollTo ";

export interface IProduct {
  id?: number;
  url: string[];
  description: string[];
  subDescription?: { header: string; text: string[] }[];
  extendDescription?: string[];
  annotation?: string[];
  displayName?: string;
  pdfLink?: string;
  table?: { label: string; content: string }[];
}

function Product() {
  const { name } = useParams();
  const data = useMemo(() => products, []);
  const [product, setProduct] = useState<IProductDetail>();
  const navigate = useNavigate();
  useEffect(() => {
    let foundProduct = data.filter((p) => p.name === name)[0];
    if (foundProduct) {
      setProduct(foundProduct);
    }

    if (!foundProduct && name) {
      navigate("/404");
    }
  }, [name, data, navigate]);
  useScrollTo(0, 0);
  return (
    <>
      {name ? (
        product && <ProductDetail data={product} />
      ) : (
        <Products data={data}></Products>
      )}
    </>
  );
}

export default Product;
