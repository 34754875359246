import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Flickity, { FlickityOptions } from "react-flickity-component";
import "./animationCarousel.css";

function AnimationCarousel() {
  const [height, setHeight] = useState("550px");
  const divbg = useRef<HTMLDivElement>(null);
  const [isMobileWith, setIsMobileWith] = useState(false);
  const [duration, setDuration] = useState(2);

  useEffect(() => {
    setInterval(() => {
      let height = document.getElementById("cimg")?.offsetHeight;
      setHeight(`${height}px`);
      let element = document.querySelector<HTMLElement>(".flickity-viewport");
      element?.style.setProperty("height", `${height}px`);
    }, 1000);
    window.addEventListener("resize", () => {
      if (window.screen.width <= 410) {
        setIsMobileWith(true);
      } else {
        setIsMobileWith(false);
      }
      let height = document.getElementById("cimg")?.offsetHeight;
      setHeight(`${height}px`);
    });
    divbg.current?.style.setProperty(
      "height",
      `${divbg.current.offsetWidth}px`
    );
  }, []);

  const flickityOptions: FlickityOptions = {
    initialIndex: 0,
    prevNextButtons: false,
    autoPlay: 5000,
    lazyLoad: true,
    wrapAround: true,
    friction: 0.5,

  };

  return (
    <motion.div id="mainSliderContainer">
      <Flickity
        className="carousel w-100"
        elementType={"div"}
        options={flickityOptions}
        disableImagesLoaded={false}
        reloadOnUpdate
      >
        <div
          className="imagebg"
          style={{ position: "relative", width: "100%" }}
        >
          <img
            style={{ width: "100%", height: height }}
            alt="background"
            src="/assets/carousel/c1bg.png"
          />
          <div style={{ position: "absolute", top: 0 }} className="w-100 h-100">
            <div className="container h-100">
              <div className="row justify-content-left h-100">
                <motion.div
                  whileInView={{ y: isMobileWith ? [10, 0] : [200, 0], transition: { duration: duration } }}
                  className="col-6 col-sm-6 col-md-7 col-lg-7 d-flex align-items-center"
                >
                  <div className="visible-lg visible-md">
                    <h1>
                      <b>Kök Hücre Tedavisinde Uzmanların Markası</b>
                    </h1>
                    <h1>Estetik Cerrahiden Spor Cerrahisine...</h1>
                  </div>
                  <div className="visible-sm visible-xs">
                    <span>Kök Hücre Tedavisinde Uzmanların Markası</span>
                    <p>Estetik Cerrahiden Spor Cerrahisine...</p>
                  </div>
                </motion.div>
                <motion.div
                  whileInView={{ opacity: [0, 1], transition: { duration: duration } }}
                  transition={{ duration: 2 }}
                  className="col-sm-6 col-md-5 col-lg-5 col-6"
                >
                  <img
                    id="cimg"
                    src="/assets/carousel/c1front.png"
                    alt="carousel image1"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="imagebg"
          style={{ position: "relative", width: "100%" }}
        >
          <img
            style={{ width: "100%", height: height }}
            alt="background"
            src="/assets/carousel/c2bg.png"
          />
          <div style={{ position: "absolute", top: 0 }} className="w-100 h-100">
            <div className="container h-100">
              <div className="row justify-content-between h-100">
                <motion.div
                  whileInView={{ y: [-100, 0, -20], transition: { duration: duration } }}
                  className="col-sm-6 col-md-4 col-lg-4 col-5"
                >
                  <div className="h-100 d-flex align-items-center">
                    <img
                      id="cimg"
                      src="/assets/carousel/c2front.png"
                      alt="carousel image2"
                    />
                  </div>
                </motion.div>
                <motion.div
                  whileInView={{ y: [100, -10], transition: { duration: duration } }}
                  className="col-7 col-sm-6 col-md-7 col-lg-8 d-flex align-items-center justify-content-end"
                >
                  <div className="visible-lg visible-md">
                    <h1>
                      <b>
                        PEPTYS, BIOACTIVE
                        <br />
                        Collagen Pepdide
                      </b>
                    </h1>
                    <h1>
                      Aşil Tendon Tedavisinde, Direkt Doku <br />
                      İçine Uygulanan Tek Tedavi
                    </h1>
                  </div>
                  <div className="visible-sm visible-xs">
                    <span>
                      <b>PEPTYS, BIOACTIVE Collagen Pepdide</b>
                    </span>
                    <p>
                      Aşil Tendon Tedavisinde, Direkt Doku İçine Uygulanan Tek
                      Tedavi
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="imagebg"
          style={{ position: "relative", width: "100%" }}
        >
          <img
            style={{ width: "100%", height: height }}
            alt="background"
            src="/assets/carousel/c3bg.png"
          />
          <div style={{ position: "absolute", top: 0 }} className="w-100 h-100">
            <div className="container h-100">
              <div className="row justify-content-between h-100">
                <motion.div
                  whileInView={{ x: [0, 100, 0], transition: { duration: duration } }}
                  transition={{ duration: 1.5 }}
                  className="col-6 col-sm-6 col-md-5 col-lg-4"
                >
                  <div className="h-100 d-flex align-items-center">
                    <img
                      id="cimg"
                      src="/assets/carousel/c3front.png"
                      alt="carousel image3"
                    />
                  </div>
                </motion.div>
                <motion.div
                  whileInView={{ y: [-100, 0], transition: { duration: duration } }}
                  className="col-6 d-flex align-items-center"
                >
                  <div className="visible-sm visible-xs">
                    <span>
                      <b>ORTHOFLEX</b>
                    </span>
                    <br />
                    <p>Aktif Yaşamın Her Anında</p>
                  </div>
                  <div className="visible-lg visible-md">
                    <h1>
                      <b>ORTHOFLEX</b>
                    </h1>
                    <h1>Aktif Yaşamın Her Anında</h1>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </Flickity>
    </motion.div>
  );
}

export default AnimationCarousel;
