import React, { useMemo } from "react"
import Flickity, { FlickityOptions } from "react-flickity-component"
import { Link } from "react-router-dom"
import data from "../../datas/secondCarousel.json"
import "./secondSlider.css"

function SecondSlider() {
    const datas = useMemo(() => data, [])
    const flickityOptions: FlickityOptions = {
        initialIndex: 0,
        prevNextButtons: false,
        autoPlay: 5000,
        lazyLoad: true,
        friction: 0.5,
        wrapAround: true
    };
    return (
        <div className="secondSlider">
            <Flickity
                className="carousel container"
                elementType={"div"}
                options={flickityOptions}
                disableImagesLoaded={false}
                reloadOnUpdate
            >

                {datas && data.length > 0 && data.map((item, index) =>
                    <div key={index}>
                        <div className="row hidden-md hidden-sm hidden-xs">
                            <div className="text-left col-md-6">
                                <h1>{item.header}</h1>
                                <div className="lead" dangerouslySetInnerHTML={{ __html: item.content && JSON.parse(item.content) }}>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1" style={{ padding: "34px" }}>
                                <Link to={item.link}><img style={{ borderRadius: "50%", objectFit: "cover" }} alt={item.content} src={item.url} /></Link>
                            </div>
                        </div>
                        <div className="row d-flex text-center hidden-lg hidden-xxl visible-md visible-sm visible-xs">
                            <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 col-6 offset-3 ">
                                <Link to={item.link}><img style={{ borderRadius: "50%", objectFit: "cover" }} alt={item.content} src={item.url} /></Link>
                            </div>
                            <br />
                            <div className="col-md-6 offset-md-3 col-sm-8 offset-sm-2">
                                <h1>{item.header}</h1>
                                <div className="lead" dangerouslySetInnerHTML={{ __html: item.content && JSON.parse(item.content) }}>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Flickity>
        </div>
    )
}

export default SecondSlider