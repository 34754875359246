import { useEffect } from "react";
import { Link } from "react-router-dom";
import TreatmentSlider from "./sliders/ThreatmentSlider";
import "./treatmens.css";

function Treatment() {
  let data = [
    {
      imgUrl: "/assets/treatmens/eklem.png",
      name: "EKLEM SAĞLIĞI",
      url: "/tedavi-alanlari/eklem-sagligi",
    },
    {
      imgUrl: "/assets/treatmens/spor.png",
      name: "SPOR CERRAHİSİ",
      url: "/tedavi-alanlari/spor-cerrahisi",
    },
    {
      imgUrl: "/assets/treatmens/eklem.png",
      name: "MEDİKAL ESTETİK",
      url: "/tedavi-alanlari/eklem-sagligi",
    },
    {
      imgUrl: "/assets/treatmens/jinekoloji.png",
      name: "JİNEKOLOJİ",
      url: "/tedavi-alanlari/jinekoloji",
    },
    {
      imgUrl: "/assets/treatmens/uroloji.png",
      name: "ÜROLOJİ",
      url: "/tedavi-alanlari/uroloji",
    },
  ];

  useEffect(() => {
    let js = document.getElementById("flickity-scripts");
    if (js) {
      js.remove();
    }
    const script = document.createElement("script");
    script.id = "flickity-scripts";
    script.src = "/js/flickity-scripts.js"; //(This is external js url)
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div id="treatments">
      <div className="bottomBorderC">
        <h1 className="bottomBorder">TEDAVİ ALANLARIMIZ</h1>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        <div
          id="treatments"
          className="row w-100 justify-content-between hidden-md hidden-sm hidden-xs"
        >
          <div className="col-lg-2">
            <Link to="/tedavi-alanlari/eklem-sagligi">
              <img src="/assets/treatmens/eklem.png" alt="" />
              <div>
                <p>EKLEM SAĞLIĞI</p>
              </div>
            </Link>
          </div>
          {
            <div className="col-lg-2">
              <Link to="/tedavi-alanlari/spor-cerrahisi">
                <img src="/assets/treatmens/spor.png" alt="" />
                <p>SPOR CERRAHİSİ</p>
              </Link>
            </div>
          }
          <div className="col-lg-2">
            <Link to="/tedavi-alanlari/medikal-estetik">
              <img src="/assets/treatmens/estetik.png" alt="" />
              <p>MEDİKAL ESTETİK</p>
            </Link>
          </div>
          <div className="col-lg-2">
            <Link to="/tedavi-alanlari/jinekoloji">
              <img src="/assets/treatmens/jinekoloji.png" alt="" />
              <p>JİNEKOLOJİ</p>
            </Link>
          </div>
          <div className="col-lg-2">
            <Link to="/tedavi-alanlari/uroloji">
              <img src="/assets/treatmens/uroloji.png" alt="" />
              <p>ÜROLOJİ</p>
            </Link>
          </div>
        </div>
        <TreatmentSlider data={data}></TreatmentSlider>
      </div>
    </div>
  );
}

export default Treatment;
