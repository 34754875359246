import React, { useEffect } from "react";
import ProductSlider from "../sliders/ProductSlider";
import CarouselSlider from "../sliders/CarouselSlider";
import { motion } from "framer-motion";

export interface IProductDetail {
  id: number;
  url: string[];
  name: string;
  description?: string[];
  subDescription?: { header: string; text: string[] }[];
  subDescription2?: { header: string; text: string[] }[];
  displayName: string;
  pdfLink?: string;
  annotation?: string[];
  table?: { label: string; content: string }[];
  videoUrl?: string;
}

function ProductDetail(props: { data: IProductDetail }) {
  useEffect(() => {
    let js = document.getElementById("flickity-scripts");
    if (js) {
      js.remove();
    }
  }, []);

  return (
    <motion.div
      animate={{ opacity: [0, 1] }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="container">
        <img
          src="/assets/productDetailBanner.png"
          alt="Dünya Markası Ürünler"
        />
      </div>
      <section>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-5 col-lg-5 hidden-sm hidden-xs visible-md visible-lg">
              <h2 className="text-left">
                <strong>{props.data.displayName}</strong>
              </h2>
              {props.data.description?.map((item, index) => (
                <span className="text-left d-block" key={index}>
                  {item}
                </span>
              ))}
              {!props.data.description &&
                props.data.subDescription?.map((item, index) => (
                  <>
                    <h3 className="text-left m-0">
                      <strong>{item.header}</strong>
                    </h3>
                    {item.text.map((textItem, index) => (
                      <span className="text-left d-block" key={index}>
                        {textItem}
                      </span>
                    ))}
                    <br />
                  </>
                ))}
            </div>
            <div className="col-md-7 col-lg-6 hidden-sm hidden-xs visible-md visible-lg">
              <CarouselSlider
                imgUrls={props.data.url}
                alt={props.data.displayName}
              />
            </div>
            <div className="col-12 visible-sm visible-xs hidden-md hidden-lg">
              <CarouselSlider
                imgUrls={props.data.url}
                alt={props.data.displayName}
              />
            </div>
            <div className="col-12 visible-sm visible-xs hidden-md hidden-lg">
              <h2>{props.data.displayName}</h2>
              {props.data.description?.map((item, index) => (
                <p className="text-left" key={index}>
                  {item}
                </p>
              ))}
            </div>
            <div className="col-12">
              {(props.data.subDescription ||
                props.data.annotation ||
                props.data.table) && (
                <>
                  <br />
                  <h2 className="text-left">Detaylar</h2>
                  <hr />
                </>
              )}
              {props.data.description &&
                props.data.subDescription &&
                props.data.subDescription?.map((item, index) => (
                  <div key={index}>
                    <strong className="text-left d-flex">{item.header}</strong>
                    {item.text.map((content, index) => (
                      <span className="text-left d-block" key={index}>
                        - {content}
                      </span>
                    ))}
                    <br />
                    <br />
                    <br />
                  </div>
                ))}
              {props.data.subDescription2?.map((item, index) => (
                <div key={index}>
                  <strong className="text-left d-flex">{item.header}</strong>
                  {item.text.map((content, index) => (
                    <span className="text-left d-block" key={index}>
                      - {content}
                    </span>
                  ))}
                  <br />
                  <br />
                  <br />
                </div>
              ))}
              {props.data.annotation &&
                props.data.annotation.map((item, index) => (
                  <p className="text-left" key={index}>
                    - {item}
                  </p>
                ))}
              {props.data.pdfLink && (
                <>
                  <br />
                  <br />
                  <br />
                  <div id="pdfLink" className="d-flex justify-content-center">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={props.data.pdfLink}
                    >
                      <img src="/assets/pdf_icon.png" alt="pdf icon" />
                    </a>
                  </div>
                  <br />
                  <br />
                  <br />
                </>
              )}
              {props.data.table && (
                <>
                  <br />
                  <br />
                  <br />
                  <table className="border--round table--alternate-row text-start">
                    <tbody>
                      {props.data.table.map((item, index) => (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>{item.content}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <br />
                  <br />
                  <br />
                </>
              )}
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        {props.data.videoUrl && (
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
            className="container"
            src={props.data.videoUrl}
          ></iframe>
        )}
        <br />
        <br />
        <br />
        <br />
        <ProductSlider header="DİĞER ÜRÜNLER" />
      </section>
    </motion.div>
  );
}

export default ProductDetail;
