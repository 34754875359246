import React, { useEffect, useMemo } from 'react'
import Flickity, { FlickityOptions } from 'react-flickity-component';
import { Link } from 'react-router-dom';
import products from "../../datas/products.json"

function ProductSlider(props: { header: string }) {

    const products10 = useMemo(() => products, [])

    useEffect(() => {
        let js = document.getElementById("flickity-scripts");
        if (js) {
            js.remove()
        }
        const script = document.createElement('script');
        script.id = "flickity-scripts"
        script.src = '/js/flickity-scripts.js';   //(This is external js url)
        script.async = true;
        document.body.appendChild(script);
    }, [])
    const flickityOptions: FlickityOptions = {
        initialIndex: 0,
        prevNextButtons: true,
        autoPlay: 5000,
        lazyLoad: true,
        wrapAround: true,
        pageDots: false,
        friction: 0.5
    };
    return (
        <React.Fragment>
            <div className='bottomBorderC'>
                <h1 className='bottomBorder'>
                    {props.header}
                </h1>
            </div>
            <br />
            <Flickity
                className="carousel container"
                elementType={"div"}
                options={flickityOptions}
                disableImagesLoaded={false}
                reloadOnUpdate
            >
                {products10 && products10.length > 0 && products10.map((item, index) =>
                    <div className="col-lg-3 col-md-6 col-12" key={index}>
                        <div className="product">
                            <Link to={`/urunler/${item.name}`}>
                                <img src={item.url[0]} alt={item.displayName} />
                            </Link>
                            <Link className="block" to={`/urunler/${item.name}`}>
                                <div>
                                    <h5>{item.name}</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                )}
            </Flickity>
        </React.Fragment>
    )
}

export default ProductSlider