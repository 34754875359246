import { motion } from "framer-motion";
import React from "react";
import { useScrollTo } from "../app/useScrollTo ";
import "./corporate.css";
function Corporate() {
  useScrollTo(0, 0);
  return (
    <motion.div
      animate={{ opacity: [0, 1] }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <div className="container">
        <img
          src="/assets/corporateBanner.png"
          alt="Kök Hücreleriniz Sizi Yaşama Bağlar"
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <div id="corporate" className="container">
        <h1>Hakkımızda</h1>
        <p className="lead">
          Kurulduğu 2022 yılından itibaren Türkiye İlaç sektörüne spesifik,
          kaliteli ve güvenilir ürünler sunan Metalive Biyoteknoloji, insan
          sağlığına değer katma inancı ve toplumsal sorumluluk anlayışıyla
          çalışmalarına devam etmektedir.
        </p>
        <br />
        <p className="lead">
          Bir çok branş ve hastalıklar için destekleyici, spesifik ve özel
          ürünler sunan Metalive Biyoteknoloji’nin temel amacı, insanların yaşam
          kalitelerini artırmak ve tedavilerine destek olmaktır.
        </p>
        <br />
        <p className="lead">
          Bu sebeple, ürünlerimiz; üretimden son kullanıcıya ulaşana dek tüm
          süreçlerde, uluslararası standartlarda sağlık ürünleri sunma bilinci
          ve hassasiyeti göz önünde bulundurularak insanlara ulaştırılmaktadır.
        </p>
        <br />
        <br />
        <h1 className="borderBottom">Markalarımız</h1>
        <br />
        <br />
        <div className="row w-100">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <img src="./assets/corporate/arthrex.png" alt="arthrex" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <img src="./assets/corporate/tissyou.png" alt="tissyou" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <img src="./assets/corporate/ortobrand.png" alt="ortobrand" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
            <img src="./assets/corporate/dor.png" alt="dor" />
          </div>
        </div>
        <br />
        <br />
        <h1 className="borderBottom">Bizi Tercih Edenler</h1>
        <br />
        <br />
        <div className="row w-100">
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img
              src="./assets/corporate/almanhastanesi.png"
              alt="almanhastanesi"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/amerikanhas.png" alt="amerikanhas" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/medicana.png" alt="medicana" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/memorial.png" alt="memorial" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/acibadem.png" alt="acibadem" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/bayindir.png" alt="bayindir" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img
              src="./assets/corporate/guven_hastanesi.png"
              alt="guven_hastanesi"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/liv-hospital.png" alt="liv-hospital" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/medicalpark.png" alt="medicalpark" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/medipol.png" alt="medipol" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/palmiye.png" alt="palmiye" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img
              src="./assets/corporate/eryaman_hastanesi.png"
              alt="eryaman_hastanesi"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/alife.png" alt="alife" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img
              src="./assets/corporate/bilgihastanesi.png"
              alt="bilgihastanesi"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img
              src="./assets/corporate/100yilhastanesi.png"
              alt="100yilhastanesi"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/sisli.png" alt="sisli" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img
              src="./assets/corporate/can_hastanesi.png"
              alt="can_hastanesi"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/ortadogu.png" alt="ortadogu" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/umut.png" alt="umut" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/ufuk.png" alt="ufuk" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/ekol.jpg" alt="ufuk" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/emot.jpg" alt="ufuk" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/kent.jpg" alt="ufuk" />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mb-5">
            <img src="./assets/corporate/ozel-saglik.jpg" alt="ufuk" />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Corporate;
