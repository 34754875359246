import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Treatment from "../components/Treatment";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import "./treatmentArea.css";
import traetmentJson from "../datas/treatments.json";
import { useScrollTo } from "../app/useScrollTo ";
import { motion } from "framer-motion";

function TreatmentArea() {
  const { name } = useParams();
  const treatments = useMemo(() => traetmentJson, []);
  const [text, setText] = useState("");
  const [content, setContent] = useState<
    { id: number; text: string; displayHeader: string; isActive: boolean }[]
  >([]);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let foundContent = treatments.filter((p) => p.name === name)[0];
    if (foundContent) {
      setContent(foundContent.contents);
      setImageUrl(foundContent.image);
      setText(foundContent.contents[0].text);
    } else {
      navigate("/404");
    }
  }, [name, treatments, navigate]);

  useScrollTo(0, 0);

  const onClickText = (id: number) => {
    let foundText = content.filter((p) => p.id === id)[0];
    if (foundText) {
      setText(foundText.text);
      setContent(
        content.map((item) =>
          item.id === id
            ? { ...item, isActive: true }
            : { ...item, isActive: false }
        )
      );
    } else {
      setText("");
    }
  };

  return (
    <motion.div
      animate={{ opacity: [0, 1] }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
      className="container"
    >
      <div id="productDetailBanner">
        <img src={imageUrl} className="w-100" alt="Dünya Markası Ürünler" />
      </div>
      <div className="mt-5" id="treatmentMethods">
        {content &&
          content.length > 1 &&
          content.map((item, index) => (
            <span key={index} className="d-flex">
              {item.isActive ? (
                <BiChevronDown className="icon" />
              ) : (
                <BiChevronRight className="icon" />
              )}
              <a
                className={item.isActive ? "linkActive" : ""}
                onClick={() => onClickText(item.id)}
                href="#textAreas"
              >
                {item.displayHeader}
              </a>
            </span>
          ))}
      </div>
      <div
        id="textAreas"
        className="container p-5 text-left"
        dangerouslySetInnerHTML={{ __html: text && JSON.parse(text) }}
      ></div>

      <div id="treatmentContainer">
        <Treatment />
      </div>
    </motion.div>
  );
}

export default TreatmentArea;
