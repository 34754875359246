import React, { useMemo } from "react";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import DisplayThumbnail from "../components/DisplayThumbnail";
import { useScrollTo } from "../app/useScrollTo ";
import publicationJson from "../datas/publications.json";
import { motion } from "framer-motion";

function Publication() {
  useScrollTo(0, 0);
  let publications = useMemo(() => publicationJson, []);
  return (
    <motion.div
      animate={{ opacity: [0, 1] }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <div className="container">
        <div>
          <img
            src="/assets/productDetailBanner.png"
            alt="Dünya Markası Ürünler"
          />
        </div>
        <div id="productsContainer">
          <div className="row">
            {publications.map((item, index) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 p-5 d-flex flex-column align-items-center"
                key={index}
              >
                <DisplayThumbnail
                  fileUrl={item.link}
                  pageIndex={0}
                ></DisplayThumbnail>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={item.link}
                  className="d-flex flex-column"
                >
                  <p>{item.displayName}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Publication;

export interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement;
}
