import { Link } from "react-router-dom";
import "./productCard.css";

function ProductCard2() {
  return (
    <div id="productCard2" className="container d-flex">
      <h1 className="bottomBorder">IOBP INTRAOSSEUS BİOPLASTY® TEKNOLOJİSİ</h1>
      <br />
      <br />
      <img src="/assets/productCard2.png" alt="product card2" />
      <div>
        <Link className="btn bg--dark" to="/urunler/ARTHREX-IOSB">
          Ürüne Git
        </Link>
      </div>
    </div>
  );
}

export default ProductCard2;
