import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

function Footer() {
  return (
    <footer id="footer" className="space--sm footer-1 text-center-xs">
      <div className="container ">
        <div className="row">
          <div className="col-md-6 text-left ">
            <ul className="list-inline list--hover text-center-xs hidden-sm hidden-xs">
              <li>
                <Link to="/kurumsal">
                  <span>Kurumsal</span>
                </Link>
              </li>
              <li>
                <Link to="/urunler">
                  <span>Ürünler</span>
                </Link>
              </li>
              <li>
                <Link to="/yayinlar">
                  <span>Yayınlar</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 text-right text-center-xs">
            <ul className="social-list list-inline list--hover">
              <li>
                <a href="https://www.youtube.com/@izmirkokhucre/ ">
                  <i className="socicon socicon-youtube icon icon--xs"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/izmirkokhucre">
                  <i className="socicon socicon-twitter icon icon--xs"></i>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/izmirkokhucre/">
                  <i className="socicon socicon-facebook icon icon--xs"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/izmirkokhucre/	">
                  <i className="socicon socicon-instagram icon icon--xs"></i>
                </a>
              </li>
            </ul>
            <Link to="/iletisim" className="btn type--uppercase">
              <span className="btn__text">İletİşİm</span>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-left text-center-xs">
            <Link to="/">
              <img
                className="logo"
                src="/assets/footerBrand.png"
                alt="footer brand"
              />
            </Link>
            <span className="type--fine-print pl-4">
              Metalive {new Date().getFullYear()}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
