import "./home.css";
import ProductCard from "../components/cards/ProductCard";
import ProductCard2 from "../components/cards/ProductCard2";
import Treatment from "../components/Treatment";
import ProductSlider from "../components/sliders/ProductSlider";
import SecondSlider from "../components/sliders/SecondSlider";
import { Link } from "react-router-dom";
import AnimationCarousel from "../components/sliders/AnimationCarousel";
import { useScrollTo } from "../app/useScrollTo ";
import { motion } from "framer-motion";

function Home() {
  useScrollTo(0, 0);
  return (
    <motion.div
      animate={{ opacity: [0, 1] }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <AnimationCarousel />
      <br />
      <br className="hidden-sm hidden-xs" />
      <br />
      <SecondSlider />
      <br />
      <br />
      <br />
      <ProductCard />
      <br />
      <br />
      <br />
      <ProductCard2 />
      <br />
      <br />
      <br />
      <ProductSlider header="ÜRÜNLER" />
      <br />
      <br />
      <br />
      <br />
      <Link id="productsLink" className="btn btn-dark" to="/urunler">
        Tüm Ürünler
      </Link>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Treatment />
    </motion.div>
  );
}

export default Home;
