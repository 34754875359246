import { motion } from "framer-motion";
import { useScrollTo } from "../app/useScrollTo ";

function Contact() {
  useScrollTo(0, 0);
  return (
    <motion.div
      animate={{ opacity: [0, 1] }}
      exit={{ opacity: 0 }}
      transition={{ duration: 2 }}
    >
      <div className="container">
        <img
          src="/assets/productDetailBanner.png"
          alt="product detail banner"
        />
      </div>
      <section className="p-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 align-items-center d-flex flex-column">
              <h5 className="m-0">
                Her Türlü Sorunuz için Bizimle İletişime Geçebilirsiniz.
              </h5>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-12">
              <span style={{ color: "#bfbfbf" }}>Telefon</span>
            </div>
            <br />
            <br />
            <h5 className="col-12 m-0">+90 232 363 14 09</h5>
            <h5 className="col-12">+90 532 178 69 22</h5>
            <div className="col-12">
              <span style={{ color: "#bfbfbf" }}>E-Posta</span>
            </div>
            <br />
            <br />
            <h5 className="col-12 m-0">info@metalive.com.tr</h5>
            <br />
            <br />
            <div className="col-12">
              <span style={{ color: "#bfbfbf" }}>Adres</span>
            </div>
            <br />
            <br />
            <h5 className="col-12 m-0">
              Soğukkuyu Mah. 1847/14 Sok. Mutlu No:6
            </h5>
            <h5 className="col-12 m-0">Bayraklı – İZMİR</h5>
            <br />
            <br />
            <h5 className="col-12 m-0">
              Çalışma Saatlerimiz: Pazartesi - Cumartesi 09:00-18:00 Arası
            </h5>
            <br />
            <br />
            <h5 className="col-12 m-0">
              Dilerseniz aşağıdaki formu doldurarak bizimle irtibata
              geçebilirsiniz.
            </h5>
            <h5 className="col-12 m-0">
              En yakın zamanda size geri dönüş yapacağız.
            </h5>
          </div>
          <br />
          <br />
          <br />
          <div className="row justify-content-center no-gutters">
            <div className="col-md-10 col-lg-8">
              <div className="boxed boxed--border">
                <form
                  className="text-left form-email row mx-0"
                  data-success="Thanks for your enquiry, we'll be in touch shortly."
                  data-error="Please fill in all fields correctly."
                >
                  <div className="col-md-6">
                    <span>Ad</span>
                    <input
                      type="text"
                      name="name"
                      className="validate-required"
                    />
                  </div>
                  <div className="col-md-6">
                    <span>Soyad</span>
                    <input
                      type="text"
                      name="company"
                      className="validate-required"
                    />
                  </div>
                  <div className="col-md-6">
                    <span>Email</span>
                    <input
                      type="email"
                      name="email"
                      className="validate-required validate-email"
                    />
                  </div>
                  <div className="col-md-6">
                    <span>Telefon</span>
                    <input
                      type="tel"
                      name="phone"
                      className="validate-required"
                    />
                  </div>
                  <div className="col-md-12">
                    <span>Mesaj</span>
                    <textarea
                      rows={5}
                      name="description"
                      className="validate-required"
                      defaultValue={""}
                    />
                  </div>
                  <div className="col-md-12 boxed">
                    <button
                      type="submit"
                      className="btn btn--primary type--uppercase"
                    >
                      Gönder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1561.7677422131428!2d27.115054656991383!3d38.475284072916864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd77d60236bb9%3A0x173b83ca2224e504!2zU2_En3Vra3V5dSwgMTg0Ny8xNC4gU2suLCAzNTUyMCBCYXlyYWtsxLEvxLB6bWly!5e0!3m2!1str!2str!4v1674663092955!5m2!1str!2str" width="600" height="450" style={{ border: 0 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </section>
    </motion.div>
  );
}

export default Contact;
